// extracted by mini-css-extract-plugin
export var AngularIndusTechHireWeb = "AngularTechStacksMobile-module--AngularIndusTechHireWeb--5472f";
export var cardBg = "AngularTechStacksMobile-module--cardBg--f34a2";
export var cards = "AngularTechStacksMobile-module--cards--e4967";
export var cardsContent = "AngularTechStacksMobile-module--cardsContent--05b04";
export var description = "AngularTechStacksMobile-module--description--5aa60";
export var heading = "AngularTechStacksMobile-module--heading--5acfd";
export var iconssCard = "AngularTechStacksMobile-module--iconssCard--7c1b7";
export var nav = "AngularTechStacksMobile-module--nav--dc9d9";
export var navItem = "AngularTechStacksMobile-module--nav-item--5ef9a";
export var navbarBlock = "AngularTechStacksMobile-module--navbarBlock--02a0b";
export var tabData = "AngularTechStacksMobile-module--tabData--88318";
export var tech = "AngularTechStacksMobile-module--tech--5e233";
export var techIcon = "AngularTechStacksMobile-module--techIcon--1dfe8";
export var techImg = "AngularTechStacksMobile-module--techImg--618c2";
export var technologyIcon = "AngularTechStacksMobile-module--technologyIcon--ed227";