import { Script, graphql } from "gatsby"
import React, { useState } from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
import Banner from "../components/hire-pages-compoents/Banner"
import Statics from "../components/hire-typescript/Statics"
import Empower from "../components/hire-pages-compoents/WhyShould"
import WhyHireAngular from "../components/hire-pages-compoents/WhyHireAngular"
import Maximize from "../components/hire-pages-compoents/Maximize"
import HowHireAngular from "../components/hire-python-developers/HowHireAngular"
import HireDevelopers from "../components/hire-pages-compoents/HireDevelopers"
import AngularTechStacks from "../components/hire-api/AngularTechStacks"
import AngularTechStacksMobile from "../components/hire-api/AngularTechStacksMobile"
import AngularIndustries from "../components/hire-pages-compoents/AngularIndustries"
import QuickRead from "../components/hire-laravel-developers/QuickReads"
import Solutions from "../components/hire-pages-compoents/Solutions"
import SolutionsMobile from "../components/hire-pages-compoents/SolutionsMobile"
import Success from "../components/hire-pages-compoents/Success"
import Remote from "../components/hire-pages-compoents/Remote"
import Combination from "../components/hire-pages-compoents/FullStackCombination"
import Engineering from "../components/hire-pages-compoents/Engineering"
import Finest from "../components/hire-pages-compoents/Opportunity"

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
      />
    </>
  )
}
const api = ({ data }) => {
  console.log(data)
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }
  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  const heroSection = data?.strapiPage?.sections[0]
  const whyhire = data?.strapiPage?.sections[1]
  const why = data?.strapiPage?.sections[2]
  const maximizeAngular = data?.strapiPage?.sections[3]
  const developers = data?.strapiPage.sections[4]
  const how = data?.strapiPage?.sections[5]
  const rubust = data?.strapiPage?.sections[6]
  const remote = data?.strapiPage?.sections[7]
  const TechStacksAngular = data?.strapiPage?.sections[8]
  const cta = data?.strapiPage?.sections[9]
  const industriesAngular = data?.strapiPage?.sections[10]
  const solutions = data?.strapiPage?.sections[11]
  const awesome = data?.strapiPage?.sections[12]
  const quick = data?.strapiPage?.sections[13]
  const faqs = data?.strapiPage?.sections[14]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  const devSDetails = [
    {
      name: "Declan Chen",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/declan_dev_6b064a3562.png",
      experience: "5+ years of experience",
      desc: "Declan Chen is a highly talented API developer competent in Python, Java, and the latest API integrations, delivering robust and efficient solutions.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_df89fd802b.svg",
      reviews: "<b>5</b> Stars Reviews",
      skills: [
        "Node Js",
        "Nest Js",
        "Express js",
        "Laravel",
        "Lumen",
        "Python",
        "Django",
        "Flask",
        "FastAPI",
        "Elixir",
        "Phoenix",
      ],
    },
    {
      name: "Haris Naeem",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/haris_dev_a411307aa3.png",
      experience: "4+ years of experience",
      desc: "Haris Naeem, an adept API developer, excels in developing efficient solutions. His expertise ensures high-quality API integrations & robust backend systems.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_4_8_3cbdc850f0.svg",
      reviews: "<b>4.8</b>Stars Reviews",
      skills: [
        ".Net",
        "Rust",
        "GO",
        "GraphQL",
        "JSON",
        "XML",
        "JWT",
        "Firebase",
        "Kafka",
        "RabbitMQ",
      ],
    },
    {
      name: "Max Evans",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/max_dev_1d207b2a85.png",
      experience: "3+ Years Of Experience",
      desc: "Max Evans, an API maestro, engineers seamless and robust solutions. His mastery ensures top-notch API integrations and unparalleled backend development.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_4_6_2457829227.svg",
      reviews: "<b>4.6</b> Stars Reviews",
      skills: [
        "Python",
        "Java",
        "C#",
        "JSON",
        "XML",
        "RESTful APIs",
        "Web Services",
        "API Integration",
        "Java",
        "Spring Boot",
        "API Development",
      ],
    },
  ]

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} hireAPI={true} />
      <Statics />
      <WhyHireAngular strapiData={whyhire} />
      <Engineering strapiData={why} />
      <Maximize strapiData={maximizeAngular} hireApiHead={true} />
      <HireDevelopers
        strapiData={developers}
        devSDetails={devSDetails}
        apiExplore={true}
        exploreDev={true}
      />
      <HowHireAngular strapiData={how}  />
      <Empower strapiData={rubust} Vueidentify={true} />
      <Remote strapiData={remote} />
      {!isMobile && !isTablet ? (
        <AngularTechStacks strapiData={TechStacksAngular} />
      ) : (
        <AngularTechStacksMobile strapiData={TechStacksAngular} />
      )}
      <Finest strapiData={cta} backendBudget={true} vsJsBanner={true} />
      <AngularIndustries strapiData={industriesAngular} />
      {!isMobile && !isTablet ? (
        <Solutions strapiData={solutions} />
      ) : (
        <SolutionsMobile strapiData={solutions} />
      )}
      <Success strapiData={awesome} />
      <QuickRead strapiData={quick} />
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query api {
    strapiPage(slug: { eq: "hire-api-developers" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          subTitle2
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default api
