// extracted by mini-css-extract-plugin
export var TechStacksWebHireAngular = "AngularTechStacks-module--TechStacksWebHireAngular--531a7";
export var cardBg = "AngularTechStacks-module--cardBg--281da";
export var cards = "AngularTechStacks-module--cards--63f57";
export var cardsContentAngular = "AngularTechStacks-module--cardsContentAngular--eca13";
export var description = "AngularTechStacks-module--description--76b9a";
export var heading = "AngularTechStacks-module--heading--859b7";
export var iconssCard = "AngularTechStacks-module--iconssCard--9104c";
export var nav = "AngularTechStacks-module--nav--a1fd5";
export var navItem = "AngularTechStacks-module--nav-item--275d9";
export var navbarBlock = "AngularTechStacks-module--navbarBlock--5a283";
export var tabData = "AngularTechStacks-module--tabData--2fd08";
export var tech = "AngularTechStacks-module--tech--9985c";
export var techIcon = "AngularTechStacks-module--techIcon--d3e68";
export var techImg = "AngularTechStacks-module--techImg--7f0b5";
export var technologyIcon = "AngularTechStacks-module--technologyIcon--7d52a";