import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./AngularTechStacks.scss"
import * as styles from "./AngularTechStacks.module.scss"

const data = [
  {
    title: "Technologies",
    tech: [
      {
        name: "Node js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/node_js_700ce8f497.svg",
      },
      {
        name: "Nest Js",
        icon: "https://invozone-backend.s3.amazonaws.com/nest_js_0d8071c02c.svg",
      },
      {
        name: "Express js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/express_js_d88d76c098.svg",
      },
      {
        name: "Laravel",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/laravel_092f7fe31d.svg",
      },
      {
        name: "Lumen",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/lumen_ed55fd1512.svg",
      },
      {
        name: "Python",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/python_fa6e4ee957.svg",
      },
      {
        name: "Django",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/django_63be4b87fd.svg",
      },
      {
        name: "Flask",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/flask_9d3eb65e98.svg",
      },
      {
        name: "FastAPI",
        icon: "https://invozone-backend.s3.amazonaws.com/fastapi_c54992c5d8.svg",
      },
      {
        name: "Elixir",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/elixir_82ba1db70c.svg",
      },
      {
        name: "Phoenix",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/phoenix_e275cf4716.svg",
      },
      {
        name: "RoR",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ror_288e126ccd.svg",
      },
      {
        name: "Ruby",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ruby_6b6aa1217f.svg",
      },
      {
        name: "Java",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/javascript_51cf86eab4.svg",
      },
      {
        name: "Spring Boot",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/spring_boot_a9029a662a.svg",
      },
      {
        name: "C#",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/c_sharp_02d455b66e.svg",
      },
      {
        name: ".Net",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/asp_dot_net_fedfa889c3.svg",
      },
      {
        name: "Rust",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/rust_c587e1fbf7.svg",
      },
      {
        name: "GO",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/golang_d1242ceae6.svg",
      },
      {
        name: "GraphQL",
        icon: "https://invozone-backend.s3.amazonaws.com/graph_Ql_2_51bcd173ff.svg",
      },
      {
        name: "JSON",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/json_0249bd8f3e.svg",
      },
      {
        name: "XML",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/xml_697969efba.svg",
      },
      {
        name: "OpenAPI",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/open_api_ca796981c1.svg",
      },
      {
        name: "Amazon API",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_api_527ad546f6.svg",
      },
      {
        name: "OAuth 2.0",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Oauth_dc87d8a2c4.svg",
      },
      {
        name: "JWT",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jwt_f2ea1773e9.svg",
      },
      {
        name: "Firebase",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/firebase_4c96467ff8.svg",
      },
      {
        name: "Kafka",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/kafka_90be31a743.svg",
      },
      {
        name: "RabbitMQ",
        icon: "https://invozone-backend.s3.amazonaws.com/rabbit_3e6d3a9f46.svg",
      },
    ],
  },
  {
    title: "Databases",
    tech: [
      {
        name: "Redis",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/redis_832eeb3e2d.svg",
      },
      {
        name: "MongoDB",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mongodb_b915357e37.svg",
      },
      {
        name: "MySQL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mysql_e3712a94db.svg",
      },
      {
        name: "PostgreSQL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postgresql_749d5d482a.svg",
      },
      {
        name: "SQLite",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sqlite_ff73d52a1e.svg",
      },
      {
        name: "Firebase",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/firebase_4c96467ff8.svg",
      },
    ],
  },
  {
    title: "API Deployment Platform",
    tech: [
      {
        name: "AWS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/azure_3871b2f717.svg",
      },
      {
        name: "Microsoft Azure",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/azure_3871b2f717.svg",
      },
      {
        name: "GCP",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/gcp_devops_engineers_b3443dd1ff.svg",
      },
      {
        name: "Digital Ocean",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/digital_ocean_0cb308088c.svg",
      },
      {
        name: "Heroku",
        icon: "https://invozone-backend.s3.amazonaws.com/heroku_c83bd3b09d.svg",
      },
      {
        name: "Fly.io",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/fly_io_f0961af7cd.svg",
      },
    ],
  },
]

const AngularTechStacks = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="apiTech">
      <div className={styles.TechStacksWebHireAngular}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContentAngular}>
              <div>
                <Nav variant="pills" className={`apiTech`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlockAngularWeb">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                    style={{
                                      height: "60px",
                                      width: "60px",
                                    }}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default AngularTechStacks
